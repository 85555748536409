<template>
  <v-row>
    <v-col cols="12">
      <account-code-select v-model="obAccountTax.code" required />
    </v-col>

    <v-col cols="12" lg="6">
      <tax-types-select v-model="obAccountTax.tax_id" required />
    </v-col>

    <v-col cols="12" lg="6">
      <invoice-movement-type-select
        v-model="obAccountTax.invoice_movement_type_id"
        :movement-type-code="[1, 8, 12]"
        required
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import { AccountTax } from "@planetadeleste/vue-mc-gw";
import InvoiceMovementTypeSelect from "@/modules/invoices/components/InvoiceMovementTypeSelect.vue";
import TaxTypesSelect from "@/modules/taxtypes/components/TaxTypesSelect.vue";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";

@Component({
  components: { AccountCodeSelect, TaxTypesSelect, InvoiceMovementTypeSelect },
})
export default class AccounttaxesSettings extends Vue {
  @VModel({ type: Object, default: () => new AccountTax() })
  obAccountTax!: AccountTax;
}
</script>
